<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="formType === 'ADD' ? 'Tambah Kategori Unit' : 'Ubah Kategori Unit'"
    class="px-5 py-3"
  >
    <page-loading :show="loading" />
    <form @submit.prevent="submit">
      <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
        <label class="col-12 col-lg-3" for="nameField">Nama</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="nameField"
            v-model="name"
            type="text"
            name="name"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('name') }}</span>
        </div>
      </div>

      <div class="d-flex align-center justify-end">
        <v-btn color="primary" class="bottom--button" type="submit">
          {{ $t('project.btn.save') }}
        </v-btn>
      </div>
    </form>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'unit-type-form',
  mixins: [HelperMixin],
  components: { PageLoading },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState({
      formType: (state) => state.unitCategory.form.formType,
    }),
    loading: {
      get() {
        return this.$store.state.unitCategory.form.loading;
      },
      set(value) {
        this.$store.commit('unitCategory/form/SET_LOADING', value);
      },
    },
    name: {
      get() {
        return this.$store.state.unitCategory.form.name;
      },
      set(value) {
        this.$store.commit('unitCategory/form/SET_NAME', value);
      },
    },
  },

  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.unitCategory.name.required'));
    },
  },
  methods: {
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('unitCategory/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.publish.successMsg'),
          'success',
        );
        this.$router.back();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
